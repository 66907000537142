<template>
  <div>
      <el-dialog
          title="预览信息"
          :visible.sync="dialogVisible"
          width="80%"
          :before-close="handleClose"
      >
          <div>
              <div style="margin-bottom: 20px">
                  <el-row type="flex">
                      <!-- <el-col :span="3"><el-button type="info" @click="searchClick">复制表格</el-button></el-col> -->
                      <el-col
                          ><el-button type="primary" @click="exportClick"
                              >导出表格</el-button
                          ></el-col
                      >
                      <el-col>
                          <el-input
                              v-model="seachValue"
                              clearable
                              placeholder="请输入内容"
                              @keyup.enter.native="seachBlur"
                          ></el-input>
                      </el-col>
                  </el-row>
              </div>
              <el-table
                  :data="tableData"
                  border
                  id="out-table"
                  style="width: 100%"
                  height="500"
              >
                  <!-- 这里判断是什么类型 -->
                  <template>
                      <el-table-column
                          v-for="(col, index) in list"
                          :key="index"
                          :prop="col.props"
                          :label="col.label"
                          :width="col.width"
                          align="center"
                      >
                      </el-table-column>
                  </template>
              </el-table>
              <el-pagination
                  :page-size="pageSize"
                  :current-page="current"
                  background
                  layout="prev, pager, next"
                  :total="total"
                  @current-change="currentChange"
              >
              </el-pagination>
          </div>
      </el-dialog>
  </div>
</template>
<script>
import {
  surveyBistablePlatformApi,
  surveyDownloadApi,
  surveyDownloadCompanyTelApi,
  surveyDownloadNotCompanyTelApi,
} from '@/api/analysisResultApi.js'
// import FileSaver from 'file-saver'
// import * as XLSX from 'xlsx'
import ExportJsonExcel from "js-export-excel";
export default {
  data() {
      return {
          current: 1,
          pageSize: 10,
          total: 0,
          dialogVisible: false,
          apiName: '',
          code: '',
          tableData: [],
          list: [],
          copyEvent: false,
          exportJsonData: {
              tHeader: [],
              filterVal: [],
              list: [],
              total_info: '',
          },
          seachValue:'',
          tableList:[]
      }
  },
  mounted() {
      // setTimeout(() => {
      //     this.tableData = [
      //     ]
      // }, 2000)
  },
  methods: {
      open(value, leftValue, code) {
          this.list = []
          this.tableData = []
          this.tableDataAll = []
          if (value === 'surveyBistablePlatformApi') {
              this.surveyBistablePlatformFun()
              this.tableName = '双稳平台推送信息管理'
          } else if (value === 'surveyDownloadApi') {
              this.surveyDownloadFun(code)
              if (code === 0) {
                  this.tableName = '下载所有问卷数据'
              } else  if (code === 1){
                  this.tableName = '下载所有问卷数据（编码）'
              }
          } else if (value === 'surveyDownloadCompanyTelApi') {
              this.surveyDownloadCompanyTelFun(leftValue)
              this.tableName = '下载企业及联系方式'
          } else if (value === 'surveyDownloadNotCompanyTelApi') {
              this.surveyDownloadNotCompanyTelFun(leftValue)
              this.tableName = '下载未填报企业及联系方式'
          } else {
              // 使用分析数据
              this.surveyScoreFun(value)
              this.tableName = '下载分析结果'
          }
      },
      // 搜索
      searchClick() {
          this.copyEvent = true
          document.execCommand('copy')
          this.useEffect()
      },
      // 复制函数
      useEffect() {
          document.addEventListener('copy', (event) => {
              if (this.copyEvent) {
                  if (event.clipboardData || event.originalEvent) {
                      var clipboardData =
                          event.clipboardData ||
                          event.originalEvent.clipboardData
                      // 拼接数据
                      const first = this.list
                          .map((item) => item.label)
                          .join('\t')
                      const sec = this.tableData
                          .map((item) => {
                              return this.tableFun(item)
                          })
                          .join('\n')
                      const selection = `${first}\n${sec}`
                      clipboardData.setData(
                          'text/plain',
                          selection.toString()
                      )
                      event.preventDefault()
                      this.copyEvent = false // 关掉开关
                  }
              }
          })
      },
      tableFun(item) {
          var arr = ''
          for (var index in item) {
              arr += `${item[index]}\t`
          }
          return arr
      },
       // 失去焦点
       seachBlur() {
          if (this.seachValue) {
              let filterList = []
              for (var i in this.list) {
                  filterList.push(this.list[i].props)
              }
              this.tableList = this.tableDataAll.filter((v) => {
                  return filterList.some((key) => {
                      return v[key]
                          ? v[key].toString().indexOf(this.seachValue) > -1
                          : ''
                  })
              })
              this.tableData = this.tableList
              this.total = this.tableList.length
          } else {
              console.log('0000');
              this.tableList=[]
              this.tableData = this.tableDataAll
              this.total = this.tableDataAll.length
          }
      },
      // 导出
      exportClick() {
          this.exportCsv()
      },
      exportCsv() {
          var tHeader = this.list.map((item) => item.label)
          // var filterVal = this.list.map((item) => item.props)
          // this.exportJsonData.tHeader = tHeader //表头中文
          // this.exportJsonData.filterVal = filterVal //表头key
          const dataList = this.tableList.length>0?this.tableList: this.tableDataAll //表体数据
          let option = {};  //   option代表的就是excel文件
          let dataTable = [];   //   dataTable代表excel文件中的数据内容
          if (dataList) {
              let arr3 = []
              dataList.map((item,index) => {
              for(var i in item){
                  for (let indexs = 0; indexs < this.list.length; indexs++) {
                      const element = this.list[indexs];
                      if(element.props === i){
                          let key = element.label
                          arr3[index] = {
                              ...arr3[index],
                              [key]:item[i]
                          }
                      }
                  
                  }
                  
              }
              })
              dataTable = arr3
          }
          
          option.fileName = this.tableName;  //excel文件名
          //excel文件数据
          option.datas = [
              {
              //   excel文件的数据源
              sheetData: dataTable,
              //   excel文件sheet的表名
              sheetName: "sheet",
              //   excel文件表头名
              sheetHeader: tHeader,
              //   excel文件列名
              sheetFilter: tHeader,
              },
          ];
          //   创建ExportJsonExcel实例对象
          let toExcel = new ExportJsonExcel(option);
          //   调用保存方法
          toExcel.saveExcel();
      },
      
      // 分页
      currentChange(val) {
          var start = (val - 1) * this.pageSize
          var end = val * this.pageSize
          this.tableData = this.tableDataAll.slice(start, end)
      },
      // 获取分析数据
      surveyScoreFun(score) {
          var scoreObj = {}
          var proportion = {}
          for (let index = 0; index < score.length; index++) {
              const element = score[index]
              var obj = {
                  label: element.title,
                  props: element.code,
                  width: '',
                  sort: index,
              }

              this.list.push(obj)
              scoreObj[element.code] = element.score
              proportion[element.code] = element.proportion
                  ? element.proportion
                  : ''
              if (
                  element.input_type &&
                  element.input_type !== 'number' &&
                  element.input_type !== 'text' &&
                  element.input_type !== 'textarea'
              ) {
                  for (
                      let indexs = 0;
                      indexs < element.input_options.items.length;
                      indexs++
                  ) {
                      const elements = element.input_options.items[indexs]
                      if (elements) {
                          var objs = {
                              label: elements.text,
                              props: elements.code,
                              width: '',
                              sort: index + '.' + indexs,
                          }

                          this.list.push(objs)
                          scoreObj[elements.code] = elements.score
                          proportion[elements.code] = elements.proportion
                              ? elements.proportion
                              : ''
                      }
                  }
              }
          }
          this.tableData.push(proportion)
          this.tableData.push(scoreObj)
          this.tableDataAll = this.tableData
          this.dialogVisible = true
          this.$emit('Loadding', '分析结果')

          // console.log('this.tableData', this.tableData)
      },
      // 双稳平台推送
      async surveyBistablePlatformFun() {
          var res = await surveyBistablePlatformApi(this.$route.query.id)
          console.log('双稳平台推送res', res)
          if (res.length > 0) {
              this.surveyBistablePlatformDate(res)
              this.dialogVisible = true
          } else {
              this.dialogVisible = true
          }
          this.$emit('Loadding', 'surveyBistablePlatformApi')
      },
      // 整理双稳平台推送数据
      surveyBistablePlatformDate(date) {
          this.list = [
              {
                  label: 'ID',
                  props: 'wjdopId',
                  width: '',
                  sort: '',
              },
              {
                  label: '问题名称',
                  props: 'wjqName',
                  width: '',
                  sort: '',
              },
              {
                  label: '推送时间',
                  props: 'timeSend',
                  width: '',
                  sort: '',
              },
              {
                  label: '推送内容',
                  props: 'question',
                  width: '',
                  sort: '',
              },
              {
                  label: '推送结果',
                  props: '',
                  width: '',
                  sort: '',
              },
          ]
          date.forEach((element) => {
              console.log('element', JSON.parse(element.sendData).question)
              element.question = JSON.parse(element.sendData).question
          })
          this.tableDataAll = JSON.parse(JSON.stringify(date))
          this.tableData = date.slice(0, this.pageSize)
          this.total = date.length
      },
      // 下载所有问卷  问卷数据（编码）
      async surveyDownloadFun(code) {
          var params = {
              code,
          }
          var res = await surveyDownloadApi(this.$route.query.id, params)
          if (res.name) {
              this.surveyDownloadDate(res)
          } else {
              this.list = []
              this.total = []
          }
          this.$emit('Loadding', 'surveyDownloadApi', code)
      },
      // 调整所有问卷数据
      surveyDownloadDate(date) {
          var list = []
          for (var i in date.head) {
              var obj = {
                  label: date.head[i],
                  props: i,
                  width: '',
                  sort: '',
              }
              list.push(obj)
          }
          this.list = list
          this.total = date.data.length
          this.tableDataAll = JSON.parse(JSON.stringify(date.data))
          this.tableData = date.data.slice(0, this.pageSize)
          this.dialogVisible = true
      },
      // 下载企业及联系方式
      async surveyDownloadCompanyTelFun(leftValue) {
          console.log('leftValue', leftValue);
          if(leftValue){
               var params={
              group:leftValue.sample==='全部'?'':leftValue.sample,
              city:leftValue.city==='全部'?'':leftValue.city,
              job:leftValue.job==='全部'?'':leftValue.job,
          }
          }
         
          var res = await surveyDownloadCompanyTelApi(this.$route.query.id, params)
          if (res.length > 0) {
              this.surveyDownloadCompanyTelDate(res)
              this.dialogVisible = true
          } else {
              this.dialogVisible = true
          }
          this.$emit('Loadding', 'surveyDownloadCompanyTelApi')
      },
      // 调整企业及联系方式数据
      surveyDownloadCompanyTelDate(date) {
          this.list = [
              {
                  label: '企业代码',
                  props: 'uscc',
                  width: '',
                  sort: '',
              },
              {
                  label: '企业名称',
                  props: 'name',
                  width: '',
                  sort: '',
              },
              {
                  label: '样本组',
                  props: 'groupsName',
                  width: '',
                  sort: '',
              },
              {
                  label: '海关代码',
                  props: 'customsCode',
                  width: '',
                  sort: '',
              },
              {
                  label: '地市',
                  props: 'cityName',
                  width: '',
                  sort: '',
              },
              {
                  label: '注册地',
                  props: 'countyName',
                  width: '',
                  sort: '',
              },
              {
                  label: '联系人',
                  props: 'lmName',
                  width: '',
                  sort: '',
              },
              {
                  label: '职务',
                  props: 'lmJob',
                  width: '',
                  sort: '',
              },
              {
                  label: '手机',
                  props: 'lmTel',
                  width: '',
                  sort: '',
              },
              {
                  label: '新联系人',
                  props: 'userName',
                  width: '',
                  sort: '',
              },
              {
                  label: '新联系方式',
                  props: 'phone',
                  width: '',
                  sort: '',
              },
          ]
          for (let index = 0; index < date.length; index++) {
              const element = date[index]
              if (element.groups.length > 0) {
                  var groupsName = element.groups.map((item) => item.name)
                  this.$set(element, 'groupsName', groupsName.toString())
              }
          }
          this.total = date.length
          this.tableDataAll = JSON.parse(JSON.stringify(date))
          this.tableData = date.slice(0, this.pageSize)
      },
      // 下载未填报企业及联系方式
      async surveyDownloadNotCompanyTelFun(leftValue) {
          if(leftValue){
              var params={
              group:leftValue.sample==='全部'?'':leftValue.sample,
              city:leftValue.city==='全部'?'':leftValue.city,
              job:leftValue.job==='全部'?'':leftValue.job,
          }
          }
          
          var res = await surveyDownloadNotCompanyTelApi(this.$route.query.id, params)
          if (res.length > 0) {
              this.surveyDownloadCompanyTelDate(res)
              this.dialogVisible = true
          }
          this.$emit('Loadding', 'surveyDownloadNotCompanyTelApi')
      },
      handleClose() {
          // console.log('5555');
          this.dialogVisible = false
          // this.$emit('Loadding', false)
      },
  },
}
</script>
<style scoped></style>
