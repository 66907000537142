<template>
    <div element-loading-text="拼命加载中"  v-loading="contentLoading">
        <h1 @click="isShowClick">
            填报情况统计
            <i
                style="font-weight: bold"
                :class="isShow ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
            ></i>
        </h1>
        <div v-show="isShow">
            <div
                id="statisticsDate"
                ref="statisticsDate"
                style="height: 270px; background-color: #fff; width: 100%"
            ></div>
            <div
                id="statisticsTime"
                ref="statisticsTime"
                style="height: 270px; background-color: #fff; width: 100%"
            ></div>
        </div>

        <div>
            <div class="content-box" id="app">
                <!-- 中间echarts -->
                <div class="col-8">
                    <div>
                        <ul class="nav bg-light" id="menu" v-if="topMenu">
                            <el-row type="flex">
                                <el-col class="nav-item">
                                    <li @click="topMenuClick('#top')">
                                        <i
                                            class="nav-li"
                                            :class="
                                                topMenuValue === '#top'
                                                    ? 'nav-li-hover'
                                                    : ''
                                            "
                                        ></i>
                                        <a
                                            class="nav-link h6 text-dark"
                                            :class="
                                                topMenuValue === '#top'
                                                    ? 'a-hover'
                                                    : ''
                                            "
                                            aria-current="page"
                                            href="#top"
                                            >常规问卷</a
                                        >
                                    </li>
                                </el-col>

                                <el-col
                                    class="nav-item"
                                    v-for="(q,k) in topMenu"
                                    :key="k"
                                >
                                    <li @click="topMenuClick('#' + q.title)">
                                        <i
                                            class="nav-li"
                                            :class="
                                                topMenuValue === '#' + q.title
                                                    ? 'nav-li-hover'
                                                    : ''
                                            "
                                        ></i>
                                        <a
                                            :class="
                                                topMenuValue === '#' + q.title
                                                    ? 'a-hover'
                                                    : ''
                                            "
                                            aria-current="page"
                                            :href="'#' + q.title"
                                            >{{ q.title }}</a
                                        >
                                        <!-- </li> -->
                                    </li></el-col
                                >
                            </el-row>
                        </ul>
                    </div>
                    <el-row :gutter="24" class="echarts-content">
                        <el-col :span="24">
                            <ul
                                style="
                                    list-style: none;
                                    margin-top: 0;
                                    padding-left: 0px;
                                "
                            >
                                <a
                                    id="top"
                                    style="margin-left: 0px; padding: 0"
                                ></a>
                                <li v-for="(q,k) in question" :key="k">
                                    <h4
                                        class="text-dark"
                                        v-if="q.code"
                                        :title="q.code"
                                        :id="q.title"
                                    >
                                        <span v-if="q.index"
                                            >{{ q.index }}、</span
                                        >
                                        [{{ q.code }}]{{ q.title }}
                                    </h4>
                                    <div
                                        class="row"
                                        v-if="
                                            q.input_type === 'radio' ||
                                            q.input_type === 'select'
                                        "
                                        style="background-color: #fff"
                                    >
                                        <el-row :gutter="24">
                                            <el-col :span="15"
                                                ><div class="col-8">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th
                                                                    class="text-center"
                                                                >
                                                                    编码2
                                                                </th>
                                                                <th
                                                                    class="text-end"
                                                                >
                                                                    选项
                                                                </th>
                                                                <th>小计</th>
                                                                <th>
                                                                    占本题参与企业比例
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody
                                                            v-if="
                                                                q.input_options
                                                            "
                                                        >
                                                            <tr
                                                                v-for="(o,k) in q
                                                                    .input_options
                                                                    .items"
                                                                :key="k"
                                                            >
                                                                <td
                                                                    class="text-center"
                                                                >
                                                                    {{ o.code }}
                                                                </td>
                                                                <td
                                                                    class="text-end"
                                                                >
                                                                    {{ o.text }}
                                                                </td>
                                                                <td
                                                                    class="text-success handler"
                                                                    @click="
                                                                        listAnswerByName(
                                                                            q.code,
                                                                            o.value,
                                                                            o.score
                                                                        )
                                                                    "
                                                                    :title="
                                                                        o.code
                                                                    "
                                                                >
                                                                    {{
                                                                        o.score
                                                                    }}
                                                                </td>
                                                                <td>
                                                                    <div
                                                                        v-if="
                                                                            o.proportion
                                                                        "
                                                                    >
                                                                        <div>
                                                                            <div>
                                                                                <el-progress
                                                                                    show-text
                                                                                    :stroke-width="
                                                                                        16
                                                                                    "
                                                                                    :percentage="
                                                                                        o.proportion
                                                                                    "
                                                                                ></el-progress>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table></div
                                            ></el-col>
                                            <el-col :span="9">
                                                <div class="col-4">
                                                    <div style="padding: 5px">
                                                        <el-button
                                                            style="
                                                                z-index: 1;
                                                                position: relative;
                                                            "
                                                            type="primary"
                                                            @click="
                                                                downloadAnswer(
                                                                    q
                                                                )
                                                            "
                                                            size="small"
                                                            >下载答案</el-button
                                                        >
                                                        <el-button
                                                            v-if="
                                                                q.isUpload ===
                                                                '1'
                                                            "
                                                            style="
                                                                z-index: 1;
                                                                position: relative;
                                                            "
                                                            type="success"
                                                            @click.native="
                                                                uploadScore(q)
                                                            "
                                                            size="small"
                                                            >上传得分</el-button
                                                        >
                                                        <el-button
                                                            v-else
                                                            type="text"
                                                            style="
                                                                color: #67c23a;
                                                            "
                                                            >已上传得分</el-button
                                                        >
                                                        <el-button
                                                            v-if="
                                                                q.isUpload ===
                                                                '0'
                                                            "
                                                            style="
                                                                z-index: 1;
                                                                position: relative;
                                                            "
                                                            type="success"
                                                            @click.native="
                                                                uploadScore(q)
                                                            "
                                                            size="small"
                                                            >更新</el-button
                                                        >
                                                    </div>
                                                    <div
                                                        :ref="q.code"
                                                        :id="q.code"
                                                        style="
                                                            height: 300px;
                                                            margin-top: -32px;
                                                        "
                                                    ></div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div
                                        class="row"
                                        v-else-if="q.input_type === 'checkbox'"
                                        style="background-color: #fff"
                                    >
                                        <div class="col-12">
                                            <table
                                                class="table table-striped table-hover"
                                            >
                                                <thead
                                                    style="
                                                        border: 1px solid #ccc;
                                                    "
                                                >
                                                    <tr>
                                                        <th class="text-center">
                                                            编码
                                                        </th>
                                                        <th class="text-end">
                                                            选项
                                                        </th>
                                                        <th>小计</th>
                                                        <th>
                                                            占本题参与企业比例
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody v-if="q.input_options">
                                                    <tr
                                                        v-for="(o,k) in q
                                                            .input_options
                                                            .items"
                                                        :key="k"
                                                    >
                                                        <td class="text-center">
                                                            {{ o.code }}
                                                        </td>
                                                        <td class="text-end">
                                                            {{ o.text }}
                                                        </td>
                                                        <td
                                                            class="text-success handler"
                                                            @click="
                                                                listAnswerByName(
                                                                    q.code,
                                                                    o.value,
                                                                    o.score
                                                                )
                                                            "
                                                            :title="o.code"
                                                        >
                                                            {{ o.score }}
                                                        </td>
                                                        <td>
                                                            <div
                                                                class="row"
                                                                v-if="
                                                                    o.proportion
                                                                "
                                                            >
                                                                <div
                                                                    class="col-8"
                                                                >
                                                                    <div
                                                                        class="progress"
                                                                    >
                                                                        <el-progress
                                                                            show-text
                                                                            :stroke-width="
                                                                                16
                                                                            "
                                                                            :percentage="
                                                                                o.proportion
                                                                            "
                                                                        ></el-progress>
                                                                        <!-- <div
                                                                      class="progress-bar bg-success"
                                                                      role="progressbar"
                                                                      :style="
                                                                          'width:' +
                                                                          o.proportion +
                                                                          '%;'
                                                                      "
                                                                      :aria-valuenow="
                                                                          o.proportion
                                                                      "
                                                                      aria-valuemin="0"
                                                                      aria-valuemax="100"
                                                                  ></div> -->
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col-4">
                                                              {{
                                                                  o.proportion
                                                              }}%
                                                          </div> -->
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div
                                        class="row"
                                        v-else-if="
                                            q.input_type === 'text' ||
                                            q.input_type === 'textarea' ||
                                            q.input_type === 'number'
                                        "
                                        style="background-color: #fff"
                                    >
                                        <div
                                            class="col-12"
                                            style="
                                                max-height: 300px;
                                                overflow: auto;
                                            "
                                        >
                                            <table
                                                class="table table-striped table-hover"
                                            >
                                                <thead>
                                                    <tr
                                                        style="
                                                            border-bottom: 1px
                                                                slo;
                                                        "
                                                    >
                                                        <th class="text-center">
                                                            内容
                                                        </th>
                                                        <th>小计</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr
                                                        v-for="(a,k) in q.answer"
                                                        :key="k"
                                                    >
                                                        <td
                                                            class="text-center"
                                                            v-html="a.value"
                                                        ></td>
                                                        <td
                                                            class="text-success handler"
                                                            @click="
                                                                listAnswerByName(
                                                                    q.code,
                                                                    a.value,
                                                                    a.score
                                                                )
                                                            "
                                                            :title="a.name"
                                                            v-if="a.value"
                                                        >
                                                            {{ a.score }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div
                                        class="bg-success"
                                        style="padding: 10px"
                                        v-else
                                        v-html="q.title_as"
                                        :id="q.title"
                                    ></div>
                                </li>
                            </ul>
                        </el-col>
                        <!-- <el-col :span="5">
                                </el-col
                              > -->
                    </el-row>
                    <!-- <div id="statisticsHour" style="height: 300px"></div>
                          <div id="statisticsDate" style="height: 300px"></div> -->
                </div>
            </div>
        </div>
        <!-- 上传得分弹窗 -->
        <el-dialog title="上传得分" :visible.sync="dialogVisible" width="30%">
            <div>
                <el-upload
                    class="upload-demo"
                    ref="upload"
                    action=""
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                    :on-change="handelChange"
                    name="cover"
                    :multiple="true"
                    :auto-upload="false"
                >
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">
                        只能上传xlsx、xls文件，且不超过500kb
                    </div>
                </el-upload>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitUpdate"
                    >上 传</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>
<script>
import * as Chart from 'echarts'
import {
    surveyScoreApi,
    hourSegmentStatisticsApi,
    dateSegmentStatisticsApi,
    importAnswerNewApi,
} from '@/api/analysisResultApi'
export default {
    //   props:{
    //     id:{
    //       type:Number
    //     },
    //     // leftParams:{
    //     //   type:Object
    //     // }
    //   },
    props: ['id'],
    data() {
        return {
            leftParams: {},
            isShow: false,
            action:
                process.env.VUE_APP_BASE_API +
                '/doftec/admin-survey/importData',
            dialogVisible: false,
            // id: null,

            // params: {
            //     sample: '全部',
            //     city: '全部',
            //     job: '全部',
            // },

            companyTotal: null,
            question: [],
            haveEc: ['radio', 'checkbox', 'select'],
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c}%',
                },
                legend: {
                    show: false,
                    top: 'center',
                    left: '5%',
                },
                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                    },
                },
                series: [
                    {
                        name: '占本题参与企业比例(%)',
                        type: 'pie',
                        radius: ['28%', '60%'],
                        avoidLabelOverlap: true,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '18',
                                fontWeight: 'bold',
                            },
                        },
                        itemStyle: {
                            normal: {
                                label: {
                                    show: true,
                                    formatter: '{b} : {c}%',
                                },
                                labelLine: { show: true },
                            },
                        },
                        labelLine: {
                            show: true,
                            length: 15,
                            length2: 0,
                            maxSurfaceAngle: 80,
                        },
                        data: [],
                    },
                ],
            },
            topMenuValue: '',
            fileList: [],
            contentLoading:false
        }
    },
    watch: {
        id() {
            this.contentLoading=true
            this.getSurveyScoreFun() // 带有比分的数据
        },
    },
    async created() {
        //   this.id = this.$route.query.id
        if (this.id) {
            this.contentLoading=true
            this.getSurveyScoreFun() // 带有比分的数据
        }
    },
    mounted() {
        // this.analysisDateFun()
    },
    computed: {
        topMenu() {
            var m = []
            console.log('this.question', this.question)
            if (this.question) {
                for (var q of this.question) {
                    if (q && !q.code) {
                        m.push(q)
                    }
                }
            }
            return m
        },
    },
    methods: {
        open(value) {
            this.contentLoading=true
            this.leftParams = value
            this.getSurveyScoreFun() // 带有比分的数据
        },

        // 显示填报数据
        isShowClick() {
            this.isShow = !this.isShow
            if (this.isShow) {
                this.analysisDateFun()
                this.analysisTimeFun()
            }
        },
        // 下载答案
        downloadAnswer(q) {
            this.download(
                '/doftec/admin-survey/downloadAnswerNew?wjdoId=' +
                    this.id +
                    '&wjqName=' +
                    q.code,
                `${q.title}.xls`
            )
        },
        // 上传得分
        uploadScore(row) {
            // console.log('row', row)
            this.code = row.code
            this.dialogVisible = true
            // importAnswerNewApi
        },
        // 上传得分
        async submitUpdate() {
            // console.log('上传文件', this.fileList)
            //   这里需要判断一下文件大小或者类型
            //   自定义上传就需要我们使用fromdata对象来上传文件
            let formdata = new FormData()
            for (let i = 0; i < this.fileList.length; i++) {
                // 我们上传的文件保存在每个文件对象的raw里边
                formdata.append('file', this.fileList[i].raw)
            }
            //   添加其他属性
            formdata.append('wjqName', this.code)
            formdata.append('wjdoId', this.id)
            // 发送请求
            // console.log('formdata', formdata)
            var res = await importAnswerNewApi(formdata)
            // console.log('上传返回数据', res)
              if(res.code === 200){
                this.dialogVisible=false
                this.$message.success(res.msg)
                this.getSurveyScoreFun() // 带有比分的数据
              }
        },
        handelChange(file, fileList) {
            this.fileList = fileList
            // console.log('文件修改执行的函数', file, fileList)
        },
        handleRemove(file, fileList) {
            // console.log('移除文件执行的函数', file, fileList)
            this.filesList = fileList
        },
        handlePreview(file) {
            console.log('点击已经上传的文件', file)
        },
        handleExceed(files, fileList) {
            this.$message.warning(
                `当前限制选择 3 个文件，本次选择了 ${
                    files.length
                } 个文件，共选择了 ${files.length + fileList.length} 个文件`
            )
        },
        beforeRemove(file, fileList) {
            console.log('移除之前执行的函数', fileList)
            return this.$confirm(`确定移除 ${file.name}？`)
        },
        topMenuClick(title) {
            console.log('topMenuValue', title)
            this.topMenuValue = title
        },
        async getSurveyScoreFun() {
            var params = {
                group:
                    this.leftParams.sample !== '全部'
                        ? this.leftParams.sample
                        : '',
                city:
                    this.leftParams.city !== '全部' ? this.leftParams.city : '',
                job: this.leftParams.job !== '全部' ? this.leftParams.job : '',
                uscc: this.leftParams.uscc,
            }
            var getSurveyScoreRes = await surveyScoreApi(this.id, params)
            console.log('getSurveyScoreRes', getSurveyScoreRes)
            this.question = getSurveyScoreRes.data.survey.questions
            //不写在这里会丢失html属性
            this.$nextTick(function () {
                //渲染
                for (var q of this.question) {
                    if (
                        q.input_type &&
                        this.haveEc.indexOf(q.input_type) > -1
                    ) {
                        this.doEcharts(q.code, q.echarts)
                    }
                }
                this.contentLoading = false
            })
            this.contentLoading=false
        },
        doEcharts(code, data) {
            //console.log("echarts", code, data, this.option, this.$root.echarts)
            var el = document.getElementById(code)
            if (!el) {
                return
            }
            // var myChart = this.$root.echarts.init(el)
            var myChart = Chart.init(el)

            if (!data || data.length === 0) {
                myChart.dispose()
                return
            }

            var option = this.option
            option.series[0].data = data
            if (option && typeof option === 'object') {
                myChart.setOption(option)
            }
        },
        // 小时段数据  statisticsTime
        async analysisTimeFun() {
            var res = await hourSegmentStatisticsApi(this.id)
            if (res.code !== 200) {
                return false
            }
            var statisticsTimeChart = Chart.init(this.$refs.statisticsTime)
            statisticsTimeChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                //    res.data.keys[0]+'至'+res.data.keys[res.data.keys.length-1],
                title: {
                    text:
                        this.Date.data.keys[0] +
                        ' ' +
                        res.data.keys[0] +
                        ':00 至 ' +
                        this.Date.data.keys[this.Date.data.keys.length - 1] +
                        ' ' +
                        res.data.keys[res.data.keys.length - 1] +
                        ':59',
                },
                grid: {
                    // top:0,
                    left: '2%',
                    right: '2%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: res.data.keys,
                        axisLabel: {
                            interval: 0,
                            rotate: 20,
                        },
                        axisTick: {
                            alignWithLabel: true,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                    },
                ],
                series: [
                    {
                        name: 'Direct',
                        type: 'bar',
                        barWidth: '60%',
                        data: res.data.values,
                        itemStyle: {
                            color: '#1890ff',
                        },
                    },
                ],
            })
        },
        // 每天填报情况 echarts
        async analysisDateFun() {
            var res = await dateSegmentStatisticsApi(this.id)
            this.Date = res
            if (res.code !== 200) {
                return false
            }
            var statisticsDateChart = Chart.init(this.$refs.statisticsDate)
            statisticsDateChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                    },
                },
                title: {
                    text:
                        res.data.keys[0] +
                        '至' +
                        res.data.keys[res.data.keys.length - 1],
                },
                grid: {
                    // top:0,
                    left: '2%',
                    right: '2%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        data: res.data.keys,
                        axisLabel: {
                            interval: 0,
                            rotate: 20,
                        },
                        axisTick: {
                            alignWithLabel: true,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                    },
                ],
                series: [
                    {
                        name: 'Direct',
                        type: 'bar',
                        barWidth: '60%',
                        data: res.data.values,
                        itemStyle: {
                            color: '#1890ff',
                        },
                    },
                ],
            })
        },
    },
}
</script>
<style scoped>
.company {
    background-color: rgba(242, 242, 242, 1);
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 10px;
}
.company-name {
    font-size: 12px;
    color: #409eff;
}
.company-rate {
    font-size: 12px;
    color: #19be6b;
}
.company-uscc,
.company-city {
    font-size: 12px;
    color: #333;
    word-wrap: break-word;
}
.company-dateTime {
    font-size: 12px;
    color: #333;
}
.company-noTime {
    font-size: 12px;
    color: #ff0000;
}
.left-box {
    height: 100%;
    /* height: calc(100vh - 180px); */
    overflow-y: auto;
    overflow-x: auto;
    margin-bottom: 48px;
}
.left-box::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}
.left-box::-webkit-scrollbar-track {
    background-color: #fff;
}
.left-box::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
.right-box {
    height: 100%;
    /* height: calc(100vh - 180px); */
    overflow-y: auto;
    overflow-x: hidden;
    margin-bottom: 48px;
    padding-bottom: 10px;
}
.right-box::-webkit-scrollbar {
    width: 7px;
}
.right-box::-webkit-scrollbar-track {
    background-color: #fff;
}
.right-box::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
.nav-item {
    list-style-type: none;
}
a {
    color: #000;
    text-decoration: none;
    margin-left: 7px;
}
.a-hover {
    color: rgb(24, 144, 255);
}
.nav-li {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #474747;
}
.nav-li-hover {
    background-color: rgb(24, 144, 255);
}
.table {
    width: 100%;
    /* border-collapse: collapse; */
    /* background-color: #fff; */
}
.text-center {
    text-align: center;
}
tr {
    height: 40px;
}
th {
    padding: 0;
    background-color: rgba(250, 250, 250, 1);
}
td {
    border-bottom: 1px solid #ccc;
    padding: 0;
}
::v-deep .el-progress {
    display: flex;
}
.flex {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 18px;
    padding: 10px;
    background-color: #fff;
    text-align: center;
}
.content-box {
}
.echarts-content {
    height: calc(100vh - 280px);
    overflow: auto;
}
.echarts-content::-webkit-scrollbar {
    width: 7px;
}
.echarts-content::-webkit-scrollbar-track {
    background-color: #fff;
}
.echarts-content::-webkit-scrollbar-thumb {
    background-color: #ddd;
    border-radius: 7px;
}
</style>
